import paths from "../paths";

let envBulletinUrl = process.env.REACT_APP_BULLETIN_URL || "https://bulletin.4nala.com";
let envWsUrl = process.env.REACT_APP_WS_URL || "wss://ws.4nala.com/stream";
let envApiUrl = process.env.REACT_APP_API_URL || "https://api.4nala.com";
// const envBulletinUrl = process.env.REACT_APP_BULLETIN_URL || "https://test-bulletin.4nala.com";
// const envWsUrl = process.env.REACT_APP_WS_URL || "wss://test-ws.4nala.com/stream";
// const envApiUrl = process.env.REACT_APP_API_URL || "https://test-api.4nala.com";

if (
  window.location.host.includes("test") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("0.0.0.0")
) {
  envBulletinUrl = process.env.REACT_APP_BULLETIN_URL || "https://test-bulletin.4nala.com";
  envWsUrl = process.env.REACT_APP_WS_URL || "wss://test-ws.4nala.com/stream";
  envApiUrl = process.env.REACT_APP_API_URL || "https://test-api.4nala.com";
}

export const bulletinUrlPrefix = `${envBulletinUrl}/api/bulletin`;
export const resultsUrlPrefix = `${envBulletinUrl}/api/results`;
export const possiblesUrlPrefix = `${envBulletinUrl}/api/possibles`;
export const agfUrlPrefix = `${envBulletinUrl}/api/agf/`;
export const agfHistoryUrlPrefix = `https://bulletin.4nala.com/api/agf-history/`;
export const programUrlPrefix = `${envBulletinUrl}/api/races`;
export const jockeyStatusUrlPrefix = `${envBulletinUrl}/api/unavailable_jockeys`;
export const changedJokeyUrlPrefix = `${envBulletinUrl}/api/jockey_changes/`;

export const env: any = {
  accounting_api: `${envApiUrl}`,
  tipsters_api: `${envApiUrl}`,
  betting_api: `${envApiUrl}`,
  cdn_host: `${envApiUrl}`,
  accounting_wss: `${envWsUrl}`,
};

export const redirectionErrorCodes = ["TC_ID_EXISTS", "EMAIL_EXISTS", "MOBILE_EXISTS"];
export let warningLogStyle = "color: black; background: yellow; font-size: 12px; font-weight:bold";
export let successLogStyle = "color: white; background: green; font-size: 12px; font-weight:bold";
export let errorLogStyle = "color: white; background: red; font-size: 12px; font-weight:bold";
export const wsFunctionTypes = [
  "checkAuth",
  "accountApproved",
  "newNotification",
  "updateSlide",
  "updatePos",
  "updateBalance",
  "updateNews",
];

export const agreements: any = {};
export const flatPages: any = {};
export const news: any = [];

export const longDays: any = {
  0: "Pazar",
  1: "Pazartesi",
  2: "Salı",
  3: "Çarşamba",
  4: "Perşembe",
  5: "Cuma",
  6: "Cumartesi",
};
export const shortDays: any = {
  0: "Pzr",
  1: "Pts",
  2: "Sal",
  3: "Çar",
  4: "Per",
  5: "Cum",
  6: "Cmt",
};
export const months = [
  { val: "01", name: "Ocak" },
  { val: "02", name: "Şubat" },
  { val: "03", name: "Mart" },
  { val: "04", name: "Nisan" },
  { val: "05", name: "Mayıs" },
  { val: "06", name: "Haziran" },
  { val: "07", name: "Temmuz" },
  { val: "08", name: "Ağustos" },
  { val: "09", name: "Eylül" },
  { val: "10", name: "Ekim" },
  { val: "11", name: "Kasım" },
  { val: "12", name: "Aralık" },
];

export const supportTicketStatus: any = {
  0: "Beklemede",
  1: "Değerlendiriliyor",
  2: "Farklı birime devredildi",
  9: "Sonuçlandı",
};

export const regions = [
  {
    state: "ADANA",
    name: "ALADAĞ",
  },
  {
    state: "ADANA",
    name: "CEYHAN",
  },
  {
    state: "ADANA",
    name: "ÇUKUROVA",
  },
  {
    state: "ADANA",
    name: "FEKE",
  },
  {
    state: "ADANA",
    name: "İMAMOĞLU",
  },
  {
    state: "ADANA",
    name: "KARAİSALI",
  },
  {
    state: "ADANA",
    name: "KARATAŞ",
  },
  {
    state: "ADANA",
    name: "KOZAN",
  },
  {
    state: "ADANA",
    name: "POZANTI",
  },
  {
    state: "ADANA",
    name: "SAİMBEYLİ",
  },
  {
    state: "ADANA",
    name: "SARIÇAM",
  },
  {
    state: "ADANA",
    name: "SEYHAN",
  },
  {
    state: "ADANA",
    name: "TUFANBEYLİ",
  },
  {
    state: "ADANA",
    name: "YUMURTALIK",
  },
  {
    state: "ADANA",
    name: "YÜREĞİR",
  },
  {
    state: "ADIYAMAN",
    name: "BESNİ",
  },
  {
    state: "ADIYAMAN",
    name: "ÇELİKHAN",
  },
  {
    state: "ADIYAMAN",
    name: "GERGER",
  },
  {
    state: "ADIYAMAN",
    name: "GÖLBAŞI",
  },
  {
    state: "ADIYAMAN",
    name: "KAHTA",
  },
  {
    state: "ADIYAMAN",
    name: "MERKEZ",
  },
  {
    state: "ADIYAMAN",
    name: "SAMSAT",
  },
  {
    state: "ADIYAMAN",
    name: "SİNCİK",
  },
  {
    state: "ADIYAMAN",
    name: "TUT",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "BAŞMAKÇI",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "BAYAT",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "BOLVADİN",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "ÇAY",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "ÇOBANLAR",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "DAZKIRI",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "DİNAR",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "EMİRDAĞ",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "EVCİLER",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "HOCALAR",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "İHSANİYE",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "İSCEHİSAR",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "KIZILÖREN",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "MERKEZ",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "SANDIKLI",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "SİNANPAŞA",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "SULTANDAĞI",
  },
  {
    state: "AFYONKARAHİSAR",
    name: "ŞUHUT",
  },
  {
    state: "AĞRI",
    name: "DİYADİN",
  },
  {
    state: "AĞRI",
    name: "DOĞUBAYAZIT",
  },
  {
    state: "AĞRI",
    name: "ELEŞKİRT",
  },
  {
    state: "AĞRI",
    name: "HAMUR",
  },
  {
    state: "AĞRI",
    name: "MERKEZ",
  },
  {
    state: "AĞRI",
    name: "PATNOS",
  },
  {
    state: "AĞRI",
    name: "TAŞLIÇAY",
  },
  {
    state: "AĞRI",
    name: "TUTAK",
  },
  {
    state: "AKSARAY",
    name: "AĞAÇÖREN",
  },
  {
    state: "AKSARAY",
    name: "ESKİL",
  },
  {
    state: "AKSARAY",
    name: "GÜLAĞAÇ",
  },
  {
    state: "AKSARAY",
    name: "GÜZELYURT",
  },
  {
    state: "AKSARAY",
    name: "MERKEZ",
  },
  {
    state: "AKSARAY",
    name: "ORTAKÖY",
  },
  {
    state: "AKSARAY",
    name: "SARIYAHŞİ",
  },
  {
    state: "AKSARAY",
    name: "SULTANHANI",
  },
  {
    state: "AMASYA",
    name: "GÖYNÜCEK",
  },
  {
    state: "AMASYA",
    name: "GÜMÜŞHACIKÖY",
  },
  {
    state: "AMASYA",
    name: "HAMAMÖZÜ",
  },
  {
    state: "AMASYA",
    name: "MERKEZ",
  },
  {
    state: "AMASYA",
    name: "MERZİFON",
  },
  {
    state: "AMASYA",
    name: "SULUOVA",
  },
  {
    state: "AMASYA",
    name: "TAŞOVA",
  },
  {
    state: "ANKARA",
    name: "AKYURT",
  },
  {
    state: "ANKARA",
    name: "ALTINDAĞ",
  },
  {
    state: "ANKARA",
    name: "AYAŞ",
  },
  {
    state: "ANKARA",
    name: "BALA",
  },
  {
    state: "ANKARA",
    name: "BEYPAZARI",
  },
  {
    state: "ANKARA",
    name: "ÇAMLIDERE",
  },
  {
    state: "ANKARA",
    name: "ÇANKAYA",
  },
  {
    state: "ANKARA",
    name: "ÇUBUK",
  },
  {
    state: "ANKARA",
    name: "ELMADAĞ",
  },
  {
    state: "ANKARA",
    name: "ETİMESGUT",
  },
  {
    state: "ANKARA",
    name: "EVREN",
  },
  {
    state: "ANKARA",
    name: "GÖLBAŞI",
  },
  {
    state: "ANKARA",
    name: "GÜDÜL",
  },
  {
    state: "ANKARA",
    name: "HAYMANA",
  },
  {
    state: "ANKARA",
    name: "KAHRAMANKAZAN",
  },
  {
    state: "ANKARA",
    name: "KALECİK",
  },
  {
    state: "ANKARA",
    name: "KEÇİÖREN",
  },
  {
    state: "ANKARA",
    name: "KIZILCAHAMAM",
  },
  {
    state: "ANKARA",
    name: "MAMAK",
  },
  {
    state: "ANKARA",
    name: "NALLIHAN",
  },
  {
    state: "ANKARA",
    name: "POLATLI",
  },
  {
    state: "ANKARA",
    name: "PURSAKLAR",
  },
  {
    state: "ANKARA",
    name: "SİNCAN",
  },
  {
    state: "ANKARA",
    name: "ŞEREFLİKOÇHİSAR",
  },
  {
    state: "ANKARA",
    name: "YENİMAHALLE",
  },
  {
    state: "ANTALYA",
    name: "AKSEKİ",
  },
  {
    state: "ANTALYA",
    name: "AKSU",
  },
  {
    state: "ANTALYA",
    name: "ALANYA",
  },
  {
    state: "ANTALYA",
    name: "DEMRE",
  },
  {
    state: "ANTALYA",
    name: "DÖŞEMEALTI",
  },
  {
    state: "ANTALYA",
    name: "ELMALI",
  },
  {
    state: "ANTALYA",
    name: "FİNİKE",
  },
  {
    state: "ANTALYA",
    name: "GAZİPAŞA",
  },
  {
    state: "ANTALYA",
    name: "GÜNDOĞMUŞ",
  },
  {
    state: "ANTALYA",
    name: "İBRADI",
  },
  {
    state: "ANTALYA",
    name: "KAŞ",
  },
  {
    state: "ANTALYA",
    name: "KEMER",
  },
  {
    state: "ANTALYA",
    name: "KEPEZ",
  },
  {
    state: "ANTALYA",
    name: "KONYAALTI",
  },
  {
    state: "ANTALYA",
    name: "KORKUTELİ",
  },
  {
    state: "ANTALYA",
    name: "KUMLUCA",
  },
  {
    state: "ANTALYA",
    name: "MANAVGAT",
  },
  {
    state: "ANTALYA",
    name: "MURATPAŞA",
  },
  {
    state: "ANTALYA",
    name: "SERİK",
  },
  {
    state: "ARDAHAN",
    name: "ÇILDIR",
  },
  {
    state: "ARDAHAN",
    name: "DAMAL",
  },
  {
    state: "ARDAHAN",
    name: "GÖLE",
  },
  {
    state: "ARDAHAN",
    name: "HANAK",
  },
  {
    state: "ARDAHAN",
    name: "MERKEZ",
  },
  {
    state: "ARDAHAN",
    name: "POSOF",
  },
  {
    state: "ARTVİN",
    name: "ARDANUÇ",
  },
  {
    state: "ARTVİN",
    name: "ARHAVİ",
  },
  {
    state: "ARTVİN",
    name: "BORÇKA",
  },
  {
    state: "ARTVİN",
    name: "HOPA",
  },
  {
    state: "ARTVİN",
    name: "KEMALPAŞA",
  },
  {
    state: "ARTVİN",
    name: "MERKEZ",
  },
  {
    state: "ARTVİN",
    name: "MURGUL",
  },
  {
    state: "ARTVİN",
    name: "ŞAVŞAT",
  },
  {
    state: "ARTVİN",
    name: "YUSUFELİ",
  },
  {
    state: "AYDIN",
    name: "BOZDOĞAN",
  },
  {
    state: "AYDIN",
    name: "BUHARKENT",
  },
  {
    state: "AYDIN",
    name: "ÇİNE",
  },
  {
    state: "AYDIN",
    name: "DİDİM",
  },
  {
    state: "AYDIN",
    name: "EFELER",
  },
  {
    state: "AYDIN",
    name: "GERMENCİK",
  },
  {
    state: "AYDIN",
    name: "İNCİRLİOVA",
  },
  {
    state: "AYDIN",
    name: "KARACASU",
  },
  {
    state: "AYDIN",
    name: "KARPUZLU",
  },
  {
    state: "AYDIN",
    name: "KOÇARLI",
  },
  {
    state: "AYDIN",
    name: "KÖŞK",
  },
  {
    state: "AYDIN",
    name: "KUŞADASI",
  },
  {
    state: "AYDIN",
    name: "KUYUCAK",
  },
  {
    state: "AYDIN",
    name: "NAZİLLİ",
  },
  {
    state: "AYDIN",
    name: "SÖKE",
  },
  {
    state: "AYDIN",
    name: "SULTANHİSAR",
  },
  {
    state: "AYDIN",
    name: "YENİPAZAR",
  },
  {
    state: "BALIKESİR",
    name: "ALTIEYLÜL",
  },
  {
    state: "BALIKESİR",
    name: "AYVALIK",
  },
  {
    state: "BALIKESİR",
    name: "BALYA",
  },
  {
    state: "BALIKESİR",
    name: "BANDIRMA",
  },
  {
    state: "BALIKESİR",
    name: "BİGADİÇ",
  },
  {
    state: "BALIKESİR",
    name: "BURHANİYE",
  },
  {
    state: "BALIKESİR",
    name: "DURSUNBEY",
  },
  {
    state: "BALIKESİR",
    name: "EDREMİT",
  },
  {
    state: "BALIKESİR",
    name: "ERDEK",
  },
  {
    state: "BALIKESİR",
    name: "GÖMEÇ",
  },
  {
    state: "BALIKESİR",
    name: "GÖNEN",
  },
  {
    state: "BALIKESİR",
    name: "HAVRAN",
  },
  {
    state: "BALIKESİR",
    name: "İVRİNDİ",
  },
  {
    state: "BALIKESİR",
    name: "KARESİ",
  },
  {
    state: "BALIKESİR",
    name: "KEPSUT",
  },
  {
    state: "BALIKESİR",
    name: "MANYAS",
  },
  {
    state: "BALIKESİR",
    name: "MARMARA",
  },
  {
    state: "BALIKESİR",
    name: "SAVAŞTEPE",
  },
  {
    state: "BALIKESİR",
    name: "SINDIRGI",
  },
  {
    state: "BALIKESİR",
    name: "SUSURLUK",
  },
  {
    state: "BARTIN",
    name: "AMASRA",
  },
  {
    state: "BARTIN",
    name: "KURUCAŞİLE",
  },
  {
    state: "BARTIN",
    name: "MERKEZ",
  },
  {
    state: "BARTIN",
    name: "ULUS",
  },
  {
    state: "BATMAN",
    name: "BEŞİRİ",
  },
  {
    state: "BATMAN",
    name: "GERCÜŞ",
  },
  {
    state: "BATMAN",
    name: "HASANKEYF",
  },
  {
    state: "BATMAN",
    name: "KOZLUK",
  },
  {
    state: "BATMAN",
    name: "MERKEZ",
  },
  {
    state: "BATMAN",
    name: "SASON",
  },
  {
    state: "BAYBURT",
    name: "AYDINTEPE",
  },
  {
    state: "BAYBURT",
    name: "DEMİRÖZÜ",
  },
  {
    state: "BAYBURT",
    name: "MERKEZ",
  },
  {
    state: "BİLECİK",
    name: "BOZÜYÜK",
  },
  {
    state: "BİLECİK",
    name: "GÖLPAZARI",
  },
  {
    state: "BİLECİK",
    name: "İNHİSAR",
  },
  {
    state: "BİLECİK",
    name: "MERKEZ",
  },
  {
    state: "BİLECİK",
    name: "OSMANELİ",
  },
  {
    state: "BİLECİK",
    name: "PAZARYERİ",
  },
  {
    state: "BİLECİK",
    name: "SÖĞÜT",
  },
  {
    state: "BİLECİK",
    name: "YENİPAZAR",
  },
  {
    state: "BİNGÖL",
    name: "ADAKLI",
  },
  {
    state: "BİNGÖL",
    name: "GENÇ",
  },
  {
    state: "BİNGÖL",
    name: "KARLIOVA",
  },
  {
    state: "BİNGÖL",
    name: "KİĞI",
  },
  {
    state: "BİNGÖL",
    name: "MERKEZ",
  },
  {
    state: "BİNGÖL",
    name: "SOLHAN",
  },
  {
    state: "BİNGÖL",
    name: "YAYLADERE",
  },
  {
    state: "BİNGÖL",
    name: "YEDİSU",
  },
  {
    state: "BİTLİS",
    name: "ADİLCEVAZ",
  },
  {
    state: "BİTLİS",
    name: "AHLAT",
  },
  {
    state: "BİTLİS",
    name: "GÜROYMAK",
  },
  {
    state: "BİTLİS",
    name: "HİZAN",
  },
  {
    state: "BİTLİS",
    name: "MERKEZ",
  },
  {
    state: "BİTLİS",
    name: "MUTKİ",
  },
  {
    state: "BİTLİS",
    name: "TATVAN",
  },
  {
    state: "BOLU",
    name: "DÖRTDİVAN",
  },
  {
    state: "BOLU",
    name: "GEREDE",
  },
  {
    state: "BOLU",
    name: "GÖYNÜK",
  },
  {
    state: "BOLU",
    name: "KIBRISCIK",
  },
  {
    state: "BOLU",
    name: "MENGEN",
  },
  {
    state: "BOLU",
    name: "MERKEZ",
  },
  {
    state: "BOLU",
    name: "MUDURNU",
  },
  {
    state: "BOLU",
    name: "SEBEN",
  },
  {
    state: "BOLU",
    name: "YENİÇAĞA",
  },
  {
    state: "BURDUR",
    name: "AĞLASUN",
  },
  {
    state: "BURDUR",
    name: "ALTINYAYLA",
  },
  {
    state: "BURDUR",
    name: "BUCAK",
  },
  {
    state: "BURDUR",
    name: "ÇAVDIR",
  },
  {
    state: "BURDUR",
    name: "ÇELTİKÇİ",
  },
  {
    state: "BURDUR",
    name: "GÖLHİSAR",
  },
  {
    state: "BURDUR",
    name: "KARAMANLI",
  },
  {
    state: "BURDUR",
    name: "KEMER",
  },
  {
    state: "BURDUR",
    name: "MERKEZ",
  },
  {
    state: "BURDUR",
    name: "TEFENNİ",
  },
  {
    state: "BURDUR",
    name: "YEŞİLOVA",
  },
  {
    state: "BURSA",
    name: "BÜYÜKORHAN",
  },
  {
    state: "BURSA",
    name: "GEMLİK",
  },
  {
    state: "BURSA",
    name: "GÜRSU",
  },
  {
    state: "BURSA",
    name: "HARMANCIK",
  },
  {
    state: "BURSA",
    name: "İNEGÖL",
  },
  {
    state: "BURSA",
    name: "İZNİK",
  },
  {
    state: "BURSA",
    name: "KARACABEY",
  },
  {
    state: "BURSA",
    name: "KELES",
  },
  {
    state: "BURSA",
    name: "KESTEL",
  },
  {
    state: "BURSA",
    name: "MUDANYA",
  },
  {
    state: "BURSA",
    name: "MUSTAFAKEMALPAŞA",
  },
  {
    state: "BURSA",
    name: "NİLÜFER",
  },
  {
    state: "BURSA",
    name: "ORHANELİ",
  },
  {
    state: "BURSA",
    name: "ORHANGAZİ",
  },
  {
    state: "BURSA",
    name: "OSMANGAZİ",
  },
  {
    state: "BURSA",
    name: "YENİŞEHİR",
  },
  {
    state: "BURSA",
    name: "YILDIRIM",
  },
  {
    state: "ÇANAKKALE",
    name: "AYVACIK",
  },
  {
    state: "ÇANAKKALE",
    name: "BAYRAMİÇ",
  },
  {
    state: "ÇANAKKALE",
    name: "BİGA",
  },
  {
    state: "ÇANAKKALE",
    name: "BOZCAADA",
  },
  {
    state: "ÇANAKKALE",
    name: "ÇAN",
  },
  {
    state: "ÇANAKKALE",
    name: "ECEABAT",
  },
  {
    state: "ÇANAKKALE",
    name: "EZİNE",
  },
  {
    state: "ÇANAKKALE",
    name: "GELİBOLU",
  },
  {
    state: "ÇANAKKALE",
    name: "GÖKÇEADA",
  },
  {
    state: "ÇANAKKALE",
    name: "LAPSEKİ",
  },
  {
    state: "ÇANAKKALE",
    name: "MERKEZ",
  },
  {
    state: "ÇANAKKALE",
    name: "YENİCE",
  },
  {
    state: "ÇANKIRI",
    name: "ATKARACALAR",
  },
  {
    state: "ÇANKIRI",
    name: "BAYRAMÖREN",
  },
  {
    state: "ÇANKIRI",
    name: "ÇERKEŞ",
  },
  {
    state: "ÇANKIRI",
    name: "ELDİVAN",
  },
  {
    state: "ÇANKIRI",
    name: "ILGAZ",
  },
  {
    state: "ÇANKIRI",
    name: "KIZILIRMAK",
  },
  {
    state: "ÇANKIRI",
    name: "KORGUN",
  },
  {
    state: "ÇANKIRI",
    name: "KURŞUNLU",
  },
  {
    state: "ÇANKIRI",
    name: "MERKEZ",
  },
  {
    state: "ÇANKIRI",
    name: "ORTA",
  },
  {
    state: "ÇANKIRI",
    name: "ŞABANÖZÜ",
  },
  {
    state: "ÇANKIRI",
    name: "YAPRAKLI",
  },
  {
    state: "ÇORUM",
    name: "ALACA",
  },
  {
    state: "ÇORUM",
    name: "BAYAT",
  },
  {
    state: "ÇORUM",
    name: "BOĞAZKALE",
  },
  {
    state: "ÇORUM",
    name: "DODURGA",
  },
  {
    state: "ÇORUM",
    name: "İSKİLİP",
  },
  {
    state: "ÇORUM",
    name: "KARGI",
  },
  {
    state: "ÇORUM",
    name: "LAÇİN",
  },
  {
    state: "ÇORUM",
    name: "MECİTÖZÜ",
  },
  {
    state: "ÇORUM",
    name: "MERKEZ",
  },
  {
    state: "ÇORUM",
    name: "OĞUZLAR",
  },
  {
    state: "ÇORUM",
    name: "ORTAKÖY",
  },
  {
    state: "ÇORUM",
    name: "OSMANCIK",
  },
  {
    state: "ÇORUM",
    name: "SUNGURLU",
  },
  {
    state: "ÇORUM",
    name: "UĞURLUDAĞ",
  },
  {
    state: "DENİZLİ",
    name: "ACIPAYAM",
  },
  {
    state: "DENİZLİ",
    name: "BABADAĞ",
  },
  {
    state: "DENİZLİ",
    name: "BAKLAN",
  },
  {
    state: "DENİZLİ",
    name: "BEKİLLİ",
  },
  {
    state: "DENİZLİ",
    name: "BEYAĞAÇ",
  },
  {
    state: "DENİZLİ",
    name: "BOZKURT",
  },
  {
    state: "DENİZLİ",
    name: "BULDAN",
  },
  {
    state: "DENİZLİ",
    name: "ÇAL",
  },
  {
    state: "DENİZLİ",
    name: "ÇAMELİ",
  },
  {
    state: "DENİZLİ",
    name: "ÇARDAK",
  },
  {
    state: "DENİZLİ",
    name: "ÇİVRİL",
  },
  {
    state: "DENİZLİ",
    name: "GÜNEY",
  },
  {
    state: "DENİZLİ",
    name: "HONAZ",
  },
  {
    state: "DENİZLİ",
    name: "KALE",
  },
  {
    state: "DENİZLİ",
    name: "MERKEZEFENDİ",
  },
  {
    state: "DENİZLİ",
    name: "PAMUKKALE",
  },
  {
    state: "DENİZLİ",
    name: "SARAYKÖY",
  },
  {
    state: "DENİZLİ",
    name: "SERİNHİSAR",
  },
  {
    state: "DENİZLİ",
    name: "TAVAS",
  },
  {
    state: "DİYARBAKIR",
    name: "BAĞLAR",
  },
  {
    state: "DİYARBAKIR",
    name: "BİSMİL",
  },
  {
    state: "DİYARBAKIR",
    name: "ÇERMİK",
  },
  {
    state: "DİYARBAKIR",
    name: "ÇINAR",
  },
  {
    state: "DİYARBAKIR",
    name: "ÇÜNGÜŞ",
  },
  {
    state: "DİYARBAKIR",
    name: "DİCLE",
  },
  {
    state: "DİYARBAKIR",
    name: "EĞİL",
  },
  {
    state: "DİYARBAKIR",
    name: "ERGANİ",
  },
  {
    state: "DİYARBAKIR",
    name: "HANİ",
  },
  {
    state: "DİYARBAKIR",
    name: "HAZRO",
  },
  {
    state: "DİYARBAKIR",
    name: "KAYAPINAR",
  },
  {
    state: "DİYARBAKIR",
    name: "KOCAKÖY",
  },
  {
    state: "DİYARBAKIR",
    name: "KULP",
  },
  {
    state: "DİYARBAKIR",
    name: "LİCE",
  },
  {
    state: "DİYARBAKIR",
    name: "SİLVAN",
  },
  {
    state: "DİYARBAKIR",
    name: "SUR",
  },
  {
    state: "DİYARBAKIR",
    name: "YENİŞEHİR",
  },
  {
    state: "DÜZCE",
    name: "AKÇAKOCA",
  },
  {
    state: "DÜZCE",
    name: "CUMAYERİ",
  },
  {
    state: "DÜZCE",
    name: "ÇİLİMLİ",
  },
  {
    state: "DÜZCE",
    name: "GÖLYAKA",
  },
  {
    state: "DÜZCE",
    name: "GÜMÜŞOVA",
  },
  {
    state: "DÜZCE",
    name: "KAYNAŞLI",
  },
  {
    state: "DÜZCE",
    name: "MERKEZ",
  },
  {
    state: "DÜZCE",
    name: "YIĞILCA",
  },
  {
    state: "EDİRNE",
    name: "ENEZ",
  },
  {
    state: "EDİRNE",
    name: "HAVSA",
  },
  {
    state: "EDİRNE",
    name: "İPSALA",
  },
  {
    state: "EDİRNE",
    name: "KEŞAN",
  },
  {
    state: "EDİRNE",
    name: "LALAPAŞA",
  },
  {
    state: "EDİRNE",
    name: "MERİÇ",
  },
  {
    state: "EDİRNE",
    name: "MERKEZ",
  },
  {
    state: "EDİRNE",
    name: "SÜLOĞLU",
  },
  {
    state: "EDİRNE",
    name: "UZUNKÖPRÜ",
  },
  {
    state: "ELAZIĞ",
    name: "AĞIN",
  },
  {
    state: "ELAZIĞ",
    name: "ALACAKAYA",
  },
  {
    state: "ELAZIĞ",
    name: "ARICAK",
  },
  {
    state: "ELAZIĞ",
    name: "BASKİL",
  },
  {
    state: "ELAZIĞ",
    name: "KARAKOÇAN",
  },
  {
    state: "ELAZIĞ",
    name: "KEBAN",
  },
  {
    state: "ELAZIĞ",
    name: "KOVANCILAR",
  },
  {
    state: "ELAZIĞ",
    name: "MADEN",
  },
  {
    state: "ELAZIĞ",
    name: "MERKEZ",
  },
  {
    state: "ELAZIĞ",
    name: "PALU",
  },
  {
    state: "ELAZIĞ",
    name: "SİVRİCE",
  },
  {
    state: "ERZİNCAN",
    name: "ÇAYIRLI",
  },
  {
    state: "ERZİNCAN",
    name: "İLİÇ",
  },
  {
    state: "ERZİNCAN",
    name: "KEMAH",
  },
  {
    state: "ERZİNCAN",
    name: "KEMALİYE",
  },
  {
    state: "ERZİNCAN",
    name: "MERKEZ",
  },
  {
    state: "ERZİNCAN",
    name: "OTLUKBELİ",
  },
  {
    state: "ERZİNCAN",
    name: "REFAHİYE",
  },
  {
    state: "ERZİNCAN",
    name: "TERCAN",
  },
  {
    state: "ERZİNCAN",
    name: "ÜZÜMLÜ",
  },
  {
    state: "ERZURUM",
    name: "AŞKALE",
  },
  {
    state: "ERZURUM",
    name: "AZİZİYE",
  },
  {
    state: "ERZURUM",
    name: "ÇAT",
  },
  {
    state: "ERZURUM",
    name: "HINIS",
  },
  {
    state: "ERZURUM",
    name: "HORASAN",
  },
  {
    state: "ERZURUM",
    name: "İSPİR",
  },
  {
    state: "ERZURUM",
    name: "KARAÇOBAN",
  },
  {
    state: "ERZURUM",
    name: "KARAYAZI",
  },
  {
    state: "ERZURUM",
    name: "KÖPRÜKÖY",
  },
  {
    state: "ERZURUM",
    name: "NARMAN",
  },
  {
    state: "ERZURUM",
    name: "OLTU",
  },
  {
    state: "ERZURUM",
    name: "OLUR",
  },
  {
    state: "ERZURUM",
    name: "PALANDÖKEN",
  },
  {
    state: "ERZURUM",
    name: "PASİNLER",
  },
  {
    state: "ERZURUM",
    name: "PAZARYOLU",
  },
  {
    state: "ERZURUM",
    name: "ŞENKAYA",
  },
  {
    state: "ERZURUM",
    name: "TEKMAN",
  },
  {
    state: "ERZURUM",
    name: "TORTUM",
  },
  {
    state: "ERZURUM",
    name: "UZUNDERE",
  },
  {
    state: "ERZURUM",
    name: "YAKUTİYE",
  },
  {
    state: "ESKİŞEHİR",
    name: "ALPU",
  },
  {
    state: "ESKİŞEHİR",
    name: "BEYLİKOVA",
  },
  {
    state: "ESKİŞEHİR",
    name: "ÇİFTELER",
  },
  {
    state: "ESKİŞEHİR",
    name: "GÜNYÜZÜ",
  },
  {
    state: "ESKİŞEHİR",
    name: "HAN",
  },
  {
    state: "ESKİŞEHİR",
    name: "İNÖNÜ",
  },
  {
    state: "ESKİŞEHİR",
    name: "MAHMUDİYE",
  },
  {
    state: "ESKİŞEHİR",
    name: "MİHALGAZİ",
  },
  {
    state: "ESKİŞEHİR",
    name: "MİHALIÇÇIK",
  },
  {
    state: "ESKİŞEHİR",
    name: "ODUNPAZARI",
  },
  {
    state: "ESKİŞEHİR",
    name: "SARICAKAYA",
  },
  {
    state: "ESKİŞEHİR",
    name: "SEYİTGAZİ",
  },
  {
    state: "ESKİŞEHİR",
    name: "SİVRİHİSAR",
  },
  {
    state: "ESKİŞEHİR",
    name: "TEPEBAŞI",
  },
  {
    state: "GAZİANTEP",
    name: "ARABAN",
  },
  {
    state: "GAZİANTEP",
    name: "İSLAHİYE",
  },
  {
    state: "GAZİANTEP",
    name: "KARKAMIŞ",
  },
  {
    state: "GAZİANTEP",
    name: "NİZİP",
  },
  {
    state: "GAZİANTEP",
    name: "NURDAĞI",
  },
  {
    state: "GAZİANTEP",
    name: "OĞUZELİ",
  },
  {
    state: "GAZİANTEP",
    name: "ŞAHİNBEY",
  },
  {
    state: "GAZİANTEP",
    name: "ŞEHİTKAMİL",
  },
  {
    state: "GAZİANTEP",
    name: "YAVUZELİ",
  },
  {
    state: "GİRESUN",
    name: "ALUCRA",
  },
  {
    state: "GİRESUN",
    name: "BULANCAK",
  },
  {
    state: "GİRESUN",
    name: "ÇAMOLUK",
  },
  {
    state: "GİRESUN",
    name: "ÇANAKÇI",
  },
  {
    state: "GİRESUN",
    name: "DERELİ",
  },
  {
    state: "GİRESUN",
    name: "DOĞANKENT",
  },
  {
    state: "GİRESUN",
    name: "ESPİYE",
  },
  {
    state: "GİRESUN",
    name: "EYNESİL",
  },
  {
    state: "GİRESUN",
    name: "GÖRELE",
  },
  {
    state: "GİRESUN",
    name: "GÜCE",
  },
  {
    state: "GİRESUN",
    name: "KEŞAP",
  },
  {
    state: "GİRESUN",
    name: "MERKEZ",
  },
  {
    state: "GİRESUN",
    name: "PİRAZİZ",
  },
  {
    state: "GİRESUN",
    name: "ŞEBİNKARAHİSAR",
  },
  {
    state: "GİRESUN",
    name: "TİREBOLU",
  },
  {
    state: "GİRESUN",
    name: "YAĞLIDERE",
  },
  {
    state: "GÜMÜŞHANE",
    name: "KELKİT",
  },
  {
    state: "GÜMÜŞHANE",
    name: "KÖSE",
  },
  {
    state: "GÜMÜŞHANE",
    name: "KÜRTÜN",
  },
  {
    state: "GÜMÜŞHANE",
    name: "MERKEZ",
  },
  {
    state: "GÜMÜŞHANE",
    name: "ŞİRAN",
  },
  {
    state: "GÜMÜŞHANE",
    name: "TORUL",
  },
  {
    state: "HAKKARİ",
    name: "ÇUKURCA",
  },
  {
    state: "HAKKARİ",
    name: "DERECİK",
  },
  {
    state: "HAKKARİ",
    name: "MERKEZ",
  },
  {
    state: "HAKKARİ",
    name: "ŞEMDİNLİ",
  },
  {
    state: "HAKKARİ",
    name: "YÜKSEKOVA",
  },
  {
    state: "HATAY",
    name: "ALTINÖZÜ",
  },
  {
    state: "HATAY",
    name: "ANTAKYA",
  },
  {
    state: "HATAY",
    name: "ARSUZ",
  },
  {
    state: "HATAY",
    name: "BELEN",
  },
  {
    state: "HATAY",
    name: "DEFNE",
  },
  {
    state: "HATAY",
    name: "DÖRTYOL",
  },
  {
    state: "HATAY",
    name: "ERZİN",
  },
  {
    state: "HATAY",
    name: "HASSA",
  },
  {
    state: "HATAY",
    name: "İSKENDERUN",
  },
  {
    state: "HATAY",
    name: "KIRIKHAN",
  },
  {
    state: "HATAY",
    name: "KUMLU",
  },
  {
    state: "HATAY",
    name: "PAYAS",
  },
  {
    state: "HATAY",
    name: "REYHANLI",
  },
  {
    state: "HATAY",
    name: "SAMANDAĞ",
  },
  {
    state: "HATAY",
    name: "YAYLADAĞI",
  },
  {
    state: "IĞDIR",
    name: "ARALIK",
  },
  {
    state: "IĞDIR",
    name: "KARAKOYUNLU",
  },
  {
    state: "IĞDIR",
    name: "MERKEZ",
  },
  {
    state: "IĞDIR",
    name: "TUZLUCA",
  },
  {
    state: "ISPARTA",
    name: "AKSU",
  },
  {
    state: "ISPARTA",
    name: "ATABEY",
  },
  {
    state: "ISPARTA",
    name: "EĞİRDİR",
  },
  {
    state: "ISPARTA",
    name: "GELENDOST",
  },
  {
    state: "ISPARTA",
    name: "GÖNEN",
  },
  {
    state: "ISPARTA",
    name: "KEÇİBORLU",
  },
  {
    state: "ISPARTA",
    name: "MERKEZ",
  },
  {
    state: "ISPARTA",
    name: "SENİRKENT",
  },
  {
    state: "ISPARTA",
    name: "SÜTÇÜLER",
  },
  {
    state: "ISPARTA",
    name: "ŞARKİKARAAĞAÇ",
  },
  {
    state: "ISPARTA",
    name: "ULUBORLU",
  },
  {
    state: "ISPARTA",
    name: "YALVAÇ",
  },
  {
    state: "ISPARTA",
    name: "YENİŞARBADEMLİ",
  },
  {
    state: "İSTANBUL",
    name: "ADALAR",
  },
  {
    state: "İSTANBUL",
    name: "ARNAVUTKÖY",
  },
  {
    state: "İSTANBUL",
    name: "ATAŞEHİR",
  },
  {
    state: "İSTANBUL",
    name: "AVCILAR",
  },
  {
    state: "İSTANBUL",
    name: "BAĞCILAR",
  },
  {
    state: "İSTANBUL",
    name: "BAHÇELİEVLER",
  },
  {
    state: "İSTANBUL",
    name: "BAKIRKÖY",
  },
  {
    state: "İSTANBUL",
    name: "BAŞAKŞEHİR",
  },
  {
    state: "İSTANBUL",
    name: "BAYRAMPAŞA",
  },
  {
    state: "İSTANBUL",
    name: "BEŞİKTAŞ",
  },
  {
    state: "İSTANBUL",
    name: "BEYKOZ",
  },
  {
    state: "İSTANBUL",
    name: "BEYLİKDÜZÜ",
  },
  {
    state: "İSTANBUL",
    name: "BEYOĞLU",
  },
  {
    state: "İSTANBUL",
    name: "BÜYÜKÇEKMECE",
  },
  {
    state: "İSTANBUL",
    name: "ÇATALCA",
  },
  {
    state: "İSTANBUL",
    name: "ÇEKMEKÖY",
  },
  {
    state: "İSTANBUL",
    name: "ESENLER",
  },
  {
    state: "İSTANBUL",
    name: "ESENYURT",
  },
  {
    state: "İSTANBUL",
    name: "EYÜPSULTAN",
  },
  {
    state: "İSTANBUL",
    name: "FATİH",
  },
  {
    state: "İSTANBUL",
    name: "GAZİOSMANPAŞA",
  },
  {
    state: "İSTANBUL",
    name: "GÜNGÖREN",
  },
  {
    state: "İSTANBUL",
    name: "KADIKÖY",
  },
  {
    state: "İSTANBUL",
    name: "KAĞITHANE",
  },
  {
    state: "İSTANBUL",
    name: "KARTAL",
  },
  {
    state: "İSTANBUL",
    name: "KÜÇÜKÇEKMECE",
  },
  {
    state: "İSTANBUL",
    name: "MALTEPE",
  },
  {
    state: "İSTANBUL",
    name: "PENDİK",
  },
  {
    state: "İSTANBUL",
    name: "SANCAKTEPE",
  },
  {
    state: "İSTANBUL",
    name: "SARIYER",
  },
  {
    state: "İSTANBUL",
    name: "SİLİVRİ",
  },
  {
    state: "İSTANBUL",
    name: "SULTANBEYLİ",
  },
  {
    state: "İSTANBUL",
    name: "SULTANGAZİ",
  },
  {
    state: "İSTANBUL",
    name: "ŞİLE",
  },
  {
    state: "İSTANBUL",
    name: "ŞİŞLİ",
  },
  {
    state: "İSTANBUL",
    name: "TUZLA",
  },
  {
    state: "İSTANBUL",
    name: "ÜMRANİYE",
  },
  {
    state: "İSTANBUL",
    name: "ÜSKÜDAR",
  },
  {
    state: "İSTANBUL",
    name: "ZEYTİNBURNU",
  },
  {
    state: "İZMİR",
    name: "ALİAĞA",
  },
  {
    state: "İZMİR",
    name: "BALÇOVA",
  },
  {
    state: "İZMİR",
    name: "BAYINDIR",
  },
  {
    state: "İZMİR",
    name: "BAYRAKLI",
  },
  {
    state: "İZMİR",
    name: "BERGAMA",
  },
  {
    state: "İZMİR",
    name: "BEYDAĞ",
  },
  {
    state: "İZMİR",
    name: "BORNOVA",
  },
  {
    state: "İZMİR",
    name: "BUCA",
  },
  {
    state: "İZMİR",
    name: "ÇEŞME",
  },
  {
    state: "İZMİR",
    name: "ÇİĞLİ",
  },
  {
    state: "İZMİR",
    name: "DİKİLİ",
  },
  {
    state: "İZMİR",
    name: "FOÇA",
  },
  {
    state: "İZMİR",
    name: "GAZİEMİR",
  },
  {
    state: "İZMİR",
    name: "GÜZELBAHÇE",
  },
  {
    state: "İZMİR",
    name: "KARABAĞLAR",
  },
  {
    state: "İZMİR",
    name: "KARABURUN",
  },
  {
    state: "İZMİR",
    name: "KARŞIYAKA",
  },
  {
    state: "İZMİR",
    name: "KEMALPAŞA",
  },
  {
    state: "İZMİR",
    name: "KINIK",
  },
  {
    state: "İZMİR",
    name: "KİRAZ",
  },
  {
    state: "İZMİR",
    name: "KONAK",
  },
  {
    state: "İZMİR",
    name: "MENDERES",
  },
  {
    state: "İZMİR",
    name: "MENEMEN",
  },
  {
    state: "İZMİR",
    name: "NARLIDERE",
  },
  {
    state: "İZMİR",
    name: "ÖDEMİŞ",
  },
  {
    state: "İZMİR",
    name: "SEFERİHİSAR",
  },
  {
    state: "İZMİR",
    name: "SELÇUK",
  },
  {
    state: "İZMİR",
    name: "TİRE",
  },
  {
    state: "İZMİR",
    name: "TORBALI",
  },
  {
    state: "İZMİR",
    name: "URLA",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "AFŞİN",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "ANDIRIN",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "ÇAĞLAYANCERİT",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "DULKADİROĞLU",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "EKİNÖZÜ",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "ELBİSTAN",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "GÖKSUN",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "NURHAK",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "ONİKİŞUBAT",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "PAZARCIK",
  },
  {
    state: "KAHRAMANMARAŞ",
    name: "TÜRKOĞLU",
  },
  {
    state: "KARABÜK",
    name: "EFLANİ",
  },
  {
    state: "KARABÜK",
    name: "ESKİPAZAR",
  },
  {
    state: "KARABÜK",
    name: "MERKEZ",
  },
  {
    state: "KARABÜK",
    name: "OVACIK",
  },
  {
    state: "KARABÜK",
    name: "SAFRANBOLU",
  },
  {
    state: "KARABÜK",
    name: "YENİCE",
  },
  {
    state: "KARAMAN",
    name: "AYRANCI",
  },
  {
    state: "KARAMAN",
    name: "BAŞYAYLA",
  },
  {
    state: "KARAMAN",
    name: "ERMENEK",
  },
  {
    state: "KARAMAN",
    name: "KAZIMKARABEKİR",
  },
  {
    state: "KARAMAN",
    name: "MERKEZ",
  },
  {
    state: "KARAMAN",
    name: "SARIVELİLER",
  },
  {
    state: "KARS",
    name: "AKYAKA",
  },
  {
    state: "KARS",
    name: "ARPAÇAY",
  },
  {
    state: "KARS",
    name: "DİGOR",
  },
  {
    state: "KARS",
    name: "KAĞIZMAN",
  },
  {
    state: "KARS",
    name: "MERKEZ",
  },
  {
    state: "KARS",
    name: "SARIKAMIŞ",
  },
  {
    state: "KARS",
    name: "SELİM",
  },
  {
    state: "KARS",
    name: "SUSUZ",
  },
  {
    state: "KASTAMONU",
    name: "ABANA",
  },
  {
    state: "KASTAMONU",
    name: "AĞLI",
  },
  {
    state: "KASTAMONU",
    name: "ARAÇ",
  },
  {
    state: "KASTAMONU",
    name: "AZDAVAY",
  },
  {
    state: "KASTAMONU",
    name: "BOZKURT",
  },
  {
    state: "KASTAMONU",
    name: "CİDE",
  },
  {
    state: "KASTAMONU",
    name: "ÇATALZEYTİN",
  },
  {
    state: "KASTAMONU",
    name: "DADAY",
  },
  {
    state: "KASTAMONU",
    name: "DEVREKANİ",
  },
  {
    state: "KASTAMONU",
    name: "DOĞANYURT",
  },
  {
    state: "KASTAMONU",
    name: "HANÖNÜ",
  },
  {
    state: "KASTAMONU",
    name: "İHSANGAZİ",
  },
  {
    state: "KASTAMONU",
    name: "İNEBOLU",
  },
  {
    state: "KASTAMONU",
    name: "KÜRE",
  },
  {
    state: "KASTAMONU",
    name: "MERKEZ",
  },
  {
    state: "KASTAMONU",
    name: "PINARBAŞI",
  },
  {
    state: "KASTAMONU",
    name: "SEYDİLER",
  },
  {
    state: "KASTAMONU",
    name: "ŞENPAZAR",
  },
  {
    state: "KASTAMONU",
    name: "TAŞKÖPRÜ",
  },
  {
    state: "KASTAMONU",
    name: "TOSYA",
  },
  {
    state: "KAYSERİ",
    name: "AKKIŞLA",
  },
  {
    state: "KAYSERİ",
    name: "BÜNYAN",
  },
  {
    state: "KAYSERİ",
    name: "DEVELİ",
  },
  {
    state: "KAYSERİ",
    name: "FELAHİYE",
  },
  {
    state: "KAYSERİ",
    name: "HACILAR",
  },
  {
    state: "KAYSERİ",
    name: "İNCESU",
  },
  {
    state: "KAYSERİ",
    name: "KOCASİNAN",
  },
  {
    state: "KAYSERİ",
    name: "MELİKGAZİ",
  },
  {
    state: "KAYSERİ",
    name: "ÖZVATAN",
  },
  {
    state: "KAYSERİ",
    name: "PINARBAŞI",
  },
  {
    state: "KAYSERİ",
    name: "SARIOĞLAN",
  },
  {
    state: "KAYSERİ",
    name: "SARIZ",
  },
  {
    state: "KAYSERİ",
    name: "TALAS",
  },
  {
    state: "KAYSERİ",
    name: "TOMARZA",
  },
  {
    state: "KAYSERİ",
    name: "YAHYALI",
  },
  {
    state: "KAYSERİ",
    name: "YEŞİLHİSAR",
  },
  {
    state: "KIRIKKALE",
    name: "BAHŞILI",
  },
  {
    state: "KIRIKKALE",
    name: "BALIŞEYH",
  },
  {
    state: "KIRIKKALE",
    name: "ÇELEBİ",
  },
  {
    state: "KIRIKKALE",
    name: "DELİCE",
  },
  {
    state: "KIRIKKALE",
    name: "KARAKEÇİLİ",
  },
  {
    state: "KIRIKKALE",
    name: "KESKİN",
  },
  {
    state: "KIRIKKALE",
    name: "MERKEZ",
  },
  {
    state: "KIRIKKALE",
    name: "SULAKYURT",
  },
  {
    state: "KIRIKKALE",
    name: "YAHŞİHAN",
  },
  {
    state: "KIRKLARELİ",
    name: "BABAESKİ",
  },
  {
    state: "KIRKLARELİ",
    name: "DEMİRKÖY",
  },
  {
    state: "KIRKLARELİ",
    name: "KOFÇAZ",
  },
  {
    state: "KIRKLARELİ",
    name: "LÜLEBURGAZ",
  },
  {
    state: "KIRKLARELİ",
    name: "MERKEZ",
  },
  {
    state: "KIRKLARELİ",
    name: "PEHLİVANKÖY",
  },
  {
    state: "KIRKLARELİ",
    name: "PINARHİSAR",
  },
  {
    state: "KIRKLARELİ",
    name: "VİZE",
  },
  {
    state: "KIRŞEHİR",
    name: "AKÇAKENT",
  },
  {
    state: "KIRŞEHİR",
    name: "AKPINAR",
  },
  {
    state: "KIRŞEHİR",
    name: "BOZTEPE",
  },
  {
    state: "KIRŞEHİR",
    name: "ÇİÇEKDAĞI",
  },
  {
    state: "KIRŞEHİR",
    name: "KAMAN",
  },
  {
    state: "KIRŞEHİR",
    name: "MERKEZ",
  },
  {
    state: "KIRŞEHİR",
    name: "MUCUR",
  },
  {
    state: "KİLİS",
    name: "ELBEYLİ",
  },
  {
    state: "KİLİS",
    name: "MERKEZ",
  },
  {
    state: "KİLİS",
    name: "MUSABEYLİ",
  },
  {
    state: "KİLİS",
    name: "POLATELİ",
  },
  {
    state: "KOCAELİ",
    name: "BAŞİSKELE",
  },
  {
    state: "KOCAELİ",
    name: "ÇAYIROVA",
  },
  {
    state: "KOCAELİ",
    name: "DARICA",
  },
  {
    state: "KOCAELİ",
    name: "DERİNCE",
  },
  {
    state: "KOCAELİ",
    name: "DİLOVASI",
  },
  {
    state: "KOCAELİ",
    name: "GEBZE",
  },
  {
    state: "KOCAELİ",
    name: "GÖLCÜK",
  },
  {
    state: "KOCAELİ",
    name: "İZMİT",
  },
  {
    state: "KOCAELİ",
    name: "KANDIRA",
  },
  {
    state: "KOCAELİ",
    name: "KARAMÜRSEL",
  },
  {
    state: "KOCAELİ",
    name: "KARTEPE",
  },
  {
    state: "KOCAELİ",
    name: "KÖRFEZ",
  },
  {
    state: "KONYA",
    name: "AHIRLI",
  },
  {
    state: "KONYA",
    name: "AKÖREN",
  },
  {
    state: "KONYA",
    name: "AKŞEHİR",
  },
  {
    state: "KONYA",
    name: "ALTINEKİN",
  },
  {
    state: "KONYA",
    name: "BEYŞEHİR",
  },
  {
    state: "KONYA",
    name: "BOZKIR",
  },
  {
    state: "KONYA",
    name: "CİHANBEYLİ",
  },
  {
    state: "KONYA",
    name: "ÇELTİK",
  },
  {
    state: "KONYA",
    name: "ÇUMRA",
  },
  {
    state: "KONYA",
    name: "DERBENT",
  },
  {
    state: "KONYA",
    name: "DEREBUCAK",
  },
  {
    state: "KONYA",
    name: "DOĞANHİSAR",
  },
  {
    state: "KONYA",
    name: "EMİRGAZİ",
  },
  {
    state: "KONYA",
    name: "EREĞLİ",
  },
  {
    state: "KONYA",
    name: "GÜNEYSINIR",
  },
  {
    state: "KONYA",
    name: "HADİM",
  },
  {
    state: "KONYA",
    name: "HALKAPINAR",
  },
  {
    state: "KONYA",
    name: "HÜYÜK",
  },
  {
    state: "KONYA",
    name: "ILGIN",
  },
  {
    state: "KONYA",
    name: "KADINHANI",
  },
  {
    state: "KONYA",
    name: "KARAPINAR",
  },
  {
    state: "KONYA",
    name: "KARATAY",
  },
  {
    state: "KONYA",
    name: "KULU",
  },
  {
    state: "KONYA",
    name: "MERAM",
  },
  {
    state: "KONYA",
    name: "SARAYÖNÜ",
  },
  {
    state: "KONYA",
    name: "SELÇUKLU",
  },
  {
    state: "KONYA",
    name: "SEYDİŞEHİR",
  },
  {
    state: "KONYA",
    name: "TAŞKENT",
  },
  {
    state: "KONYA",
    name: "TUZLUKÇU",
  },
  {
    state: "KONYA",
    name: "YALIHÜYÜK",
  },
  {
    state: "KONYA",
    name: "YUNAK",
  },
  {
    state: "KÜTAHYA",
    name: "ALTINTAŞ",
  },
  {
    state: "KÜTAHYA",
    name: "ASLANAPA",
  },
  {
    state: "KÜTAHYA",
    name: "ÇAVDARHİSAR",
  },
  {
    state: "KÜTAHYA",
    name: "DOMANİÇ",
  },
  {
    state: "KÜTAHYA",
    name: "DUMLUPINAR",
  },
  {
    state: "KÜTAHYA",
    name: "EMET",
  },
  {
    state: "KÜTAHYA",
    name: "GEDİZ",
  },
  {
    state: "KÜTAHYA",
    name: "HİSARCIK",
  },
  {
    state: "KÜTAHYA",
    name: "MERKEZ",
  },
  {
    state: "KÜTAHYA",
    name: "PAZARLAR",
  },
  {
    state: "KÜTAHYA",
    name: "SİMAV",
  },
  {
    state: "KÜTAHYA",
    name: "ŞAPHANE",
  },
  {
    state: "KÜTAHYA",
    name: "TAVŞANLI",
  },
  {
    state: "MALATYA",
    name: "AKÇADAĞ",
  },
  {
    state: "MALATYA",
    name: "ARAPGİR",
  },
  {
    state: "MALATYA",
    name: "ARGUVAN",
  },
  {
    state: "MALATYA",
    name: "BATTALGAZİ",
  },
  {
    state: "MALATYA",
    name: "DARENDE",
  },
  {
    state: "MALATYA",
    name: "DOĞANŞEHİR",
  },
  {
    state: "MALATYA",
    name: "DOĞANYOL",
  },
  {
    state: "MALATYA",
    name: "HEKİMHAN",
  },
  {
    state: "MALATYA",
    name: "KALE",
  },
  {
    state: "MALATYA",
    name: "KULUNCAK",
  },
  {
    state: "MALATYA",
    name: "PÜTÜRGE",
  },
  {
    state: "MALATYA",
    name: "YAZIHAN",
  },
  {
    state: "MALATYA",
    name: "YEŞİLYURT",
  },
  {
    state: "MANİSA",
    name: "AHMETLİ",
  },
  {
    state: "MANİSA",
    name: "AKHİSAR",
  },
  {
    state: "MANİSA",
    name: "ALAŞEHİR",
  },
  {
    state: "MANİSA",
    name: "DEMİRCİ",
  },
  {
    state: "MANİSA",
    name: "GÖLMARMARA",
  },
  {
    state: "MANİSA",
    name: "GÖRDES",
  },
  {
    state: "MANİSA",
    name: "KIRKAĞAÇ",
  },
  {
    state: "MANİSA",
    name: "KÖPRÜBAŞI",
  },
  {
    state: "MANİSA",
    name: "KULA",
  },
  {
    state: "MANİSA",
    name: "SALİHLİ",
  },
  {
    state: "MANİSA",
    name: "SARIGÖL",
  },
  {
    state: "MANİSA",
    name: "SARUHANLI",
  },
  {
    state: "MANİSA",
    name: "SELENDİ",
  },
  {
    state: "MANİSA",
    name: "SOMA",
  },
  {
    state: "MANİSA",
    name: "ŞEHZADELER",
  },
  {
    state: "MANİSA",
    name: "TURGUTLU",
  },
  {
    state: "MANİSA",
    name: "YUNUSEMRE",
  },
  {
    state: "MARDİN",
    name: "ARTUKLU",
  },
  {
    state: "MARDİN",
    name: "DARGEÇİT",
  },
  {
    state: "MARDİN",
    name: "DERİK",
  },
  {
    state: "MARDİN",
    name: "KIZILTEPE",
  },
  {
    state: "MARDİN",
    name: "MAZIDAĞI",
  },
  {
    state: "MARDİN",
    name: "MİDYAT",
  },
  {
    state: "MARDİN",
    name: "NUSAYBİN",
  },
  {
    state: "MARDİN",
    name: "ÖMERLİ",
  },
  {
    state: "MARDİN",
    name: "SAVUR",
  },
  {
    state: "MARDİN",
    name: "YEŞİLLİ",
  },
  {
    state: "MERSİN",
    name: "AKDENİZ",
  },
  {
    state: "MERSİN",
    name: "ANAMUR",
  },
  {
    state: "MERSİN",
    name: "AYDINCIK",
  },
  {
    state: "MERSİN",
    name: "BOZYAZI",
  },
  {
    state: "MERSİN",
    name: "ÇAMLIYAYLA",
  },
  {
    state: "MERSİN",
    name: "ERDEMLİ",
  },
  {
    state: "MERSİN",
    name: "GÜLNAR",
  },
  {
    state: "MERSİN",
    name: "MEZİTLİ",
  },
  {
    state: "MERSİN",
    name: "MUT",
  },
  {
    state: "MERSİN",
    name: "SİLİFKE",
  },
  {
    state: "MERSİN",
    name: "TARSUS",
  },
  {
    state: "MERSİN",
    name: "TOROSLAR",
  },
  {
    state: "MERSİN",
    name: "YENİŞEHİR",
  },
  {
    state: "MUĞLA",
    name: "BODRUM",
  },
  {
    state: "MUĞLA",
    name: "DALAMAN",
  },
  {
    state: "MUĞLA",
    name: "DATÇA",
  },
  {
    state: "MUĞLA",
    name: "FETHİYE",
  },
  {
    state: "MUĞLA",
    name: "KAVAKLIDERE",
  },
  {
    state: "MUĞLA",
    name: "KÖYCEĞİZ",
  },
  {
    state: "MUĞLA",
    name: "MARMARİS",
  },
  {
    state: "MUĞLA",
    name: "MENTEŞE",
  },
  {
    state: "MUĞLA",
    name: "MİLAS",
  },
  {
    state: "MUĞLA",
    name: "ORTACA",
  },
  {
    state: "MUĞLA",
    name: "SEYDİKEMER",
  },
  {
    state: "MUĞLA",
    name: "ULA",
  },
  {
    state: "MUĞLA",
    name: "YATAĞAN",
  },
  {
    state: "MUŞ",
    name: "BULANIK",
  },
  {
    state: "MUŞ",
    name: "HASKÖY",
  },
  {
    state: "MUŞ",
    name: "KORKUT",
  },
  {
    state: "MUŞ",
    name: "MALAZGİRT",
  },
  {
    state: "MUŞ",
    name: "MERKEZ",
  },
  {
    state: "MUŞ",
    name: "VARTO",
  },
  {
    state: "NEVŞEHİR",
    name: "ACIGÖL",
  },
  {
    state: "NEVŞEHİR",
    name: "AVANOS",
  },
  {
    state: "NEVŞEHİR",
    name: "DERİNKUYU",
  },
  {
    state: "NEVŞEHİR",
    name: "GÜLŞEHİR",
  },
  {
    state: "NEVŞEHİR",
    name: "HACIBEKTAŞ",
  },
  {
    state: "NEVŞEHİR",
    name: "KOZAKLI",
  },
  {
    state: "NEVŞEHİR",
    name: "MERKEZ",
  },
  {
    state: "NEVŞEHİR",
    name: "ÜRGÜP",
  },
  {
    state: "NİĞDE",
    name: "ALTUNHİSAR",
  },
  {
    state: "NİĞDE",
    name: "BOR",
  },
  {
    state: "NİĞDE",
    name: "ÇAMARDI",
  },
  {
    state: "NİĞDE",
    name: "ÇİFTLİK",
  },
  {
    state: "NİĞDE",
    name: "MERKEZ",
  },
  {
    state: "NİĞDE",
    name: "ULUKIŞLA",
  },
  {
    state: "ORDU",
    name: "AKKUŞ",
  },
  {
    state: "ORDU",
    name: "ALTINORDU",
  },
  {
    state: "ORDU",
    name: "AYBASTI",
  },
  {
    state: "ORDU",
    name: "ÇAMAŞ",
  },
  {
    state: "ORDU",
    name: "ÇATALPINAR",
  },
  {
    state: "ORDU",
    name: "ÇAYBAŞI",
  },
  {
    state: "ORDU",
    name: "FATSA",
  },
  {
    state: "ORDU",
    name: "GÖLKÖY",
  },
  {
    state: "ORDU",
    name: "GÜLYALI",
  },
  {
    state: "ORDU",
    name: "GÜRGENTEPE",
  },
  {
    state: "ORDU",
    name: "İKİZCE",
  },
  {
    state: "ORDU",
    name: "KABADÜZ",
  },
  {
    state: "ORDU",
    name: "KABATAŞ",
  },
  {
    state: "ORDU",
    name: "KORGAN",
  },
  {
    state: "ORDU",
    name: "KUMRU",
  },
  {
    state: "ORDU",
    name: "MESUDİYE",
  },
  {
    state: "ORDU",
    name: "PERŞEMBE",
  },
  {
    state: "ORDU",
    name: "ULUBEY",
  },
  {
    state: "ORDU",
    name: "ÜNYE",
  },
  {
    state: "OSMANİYE",
    name: "BAHÇE",
  },
  {
    state: "OSMANİYE",
    name: "DÜZİÇİ",
  },
  {
    state: "OSMANİYE",
    name: "HASANBEYLİ",
  },
  {
    state: "OSMANİYE",
    name: "KADİRLİ",
  },
  {
    state: "OSMANİYE",
    name: "MERKEZ",
  },
  {
    state: "OSMANİYE",
    name: "SUMBAS",
  },
  {
    state: "OSMANİYE",
    name: "TOPRAKKALE",
  },
  {
    state: "RİZE",
    name: "ARDEŞEN",
  },
  {
    state: "RİZE",
    name: "ÇAMLIHEMŞİN",
  },
  {
    state: "RİZE",
    name: "ÇAYELİ",
  },
  {
    state: "RİZE",
    name: "DEREPAZARI",
  },
  {
    state: "RİZE",
    name: "FINDIKLI",
  },
  {
    state: "RİZE",
    name: "GÜNEYSU",
  },
  {
    state: "RİZE",
    name: "HEMŞİN",
  },
  {
    state: "RİZE",
    name: "İKİZDERE",
  },
  {
    state: "RİZE",
    name: "İYİDERE",
  },
  {
    state: "RİZE",
    name: "KALKANDERE",
  },
  {
    state: "RİZE",
    name: "MERKEZ",
  },
  {
    state: "RİZE",
    name: "PAZAR",
  },
  {
    state: "SAKARYA",
    name: "ADAPAZARI",
  },
  {
    state: "SAKARYA",
    name: "AKYAZI",
  },
  {
    state: "SAKARYA",
    name: "ARİFİYE",
  },
  {
    state: "SAKARYA",
    name: "ERENLER",
  },
  {
    state: "SAKARYA",
    name: "FERİZLİ",
  },
  {
    state: "SAKARYA",
    name: "GEYVE",
  },
  {
    state: "SAKARYA",
    name: "HENDEK",
  },
  {
    state: "SAKARYA",
    name: "KARAPÜRÇEK",
  },
  {
    state: "SAKARYA",
    name: "KARASU",
  },
  {
    state: "SAKARYA",
    name: "KAYNARCA",
  },
  {
    state: "SAKARYA",
    name: "KOCAALİ",
  },
  {
    state: "SAKARYA",
    name: "PAMUKOVA",
  },
  {
    state: "SAKARYA",
    name: "SAPANCA",
  },
  {
    state: "SAKARYA",
    name: "SERDİVAN",
  },
  {
    state: "SAKARYA",
    name: "SÖĞÜTLÜ",
  },
  {
    state: "SAKARYA",
    name: "TARAKLI",
  },
  {
    state: "SAMSUN",
    name: "19 MAYIS",
  },
  {
    state: "SAMSUN",
    name: "ALAÇAM",
  },
  {
    state: "SAMSUN",
    name: "ASARCIK",
  },
  {
    state: "SAMSUN",
    name: "ATAKUM",
  },
  {
    state: "SAMSUN",
    name: "AYVACIK",
  },
  {
    state: "SAMSUN",
    name: "BAFRA",
  },
  {
    state: "SAMSUN",
    name: "CANİK",
  },
  {
    state: "SAMSUN",
    name: "ÇARŞAMBA",
  },
  {
    state: "SAMSUN",
    name: "HAVZA",
  },
  {
    state: "SAMSUN",
    name: "İLKADIM",
  },
  {
    state: "SAMSUN",
    name: "KAVAK",
  },
  {
    state: "SAMSUN",
    name: "LADİK",
  },
  {
    state: "SAMSUN",
    name: "SALIPAZARI",
  },
  {
    state: "SAMSUN",
    name: "TEKKEKÖY",
  },
  {
    state: "SAMSUN",
    name: "TERME",
  },
  {
    state: "SAMSUN",
    name: "VEZİRKÖPRÜ",
  },
  {
    state: "SAMSUN",
    name: "YAKAKENT",
  },
  {
    state: "SİİRT",
    name: "BAYKAN",
  },
  {
    state: "SİİRT",
    name: "ERUH",
  },
  {
    state: "SİİRT",
    name: "KURTALAN",
  },
  {
    state: "SİİRT",
    name: "MERKEZ",
  },
  {
    state: "SİİRT",
    name: "PERVARİ",
  },
  {
    state: "SİİRT",
    name: "ŞİRVAN",
  },
  {
    state: "SİİRT",
    name: "TİLLO",
  },
  {
    state: "SİNOP",
    name: "AYANCIK",
  },
  {
    state: "SİNOP",
    name: "BOYABAT",
  },
  {
    state: "SİNOP",
    name: "DİKMEN",
  },
  {
    state: "SİNOP",
    name: "DURAĞAN",
  },
  {
    state: "SİNOP",
    name: "ERFELEK",
  },
  {
    state: "SİNOP",
    name: "GERZE",
  },
  {
    state: "SİNOP",
    name: "MERKEZ",
  },
  {
    state: "SİNOP",
    name: "SARAYDÜZÜ",
  },
  {
    state: "SİNOP",
    name: "TÜRKELİ",
  },
  {
    state: "SİVAS",
    name: "AKINCILAR",
  },
  {
    state: "SİVAS",
    name: "ALTINYAYLA",
  },
  {
    state: "SİVAS",
    name: "DİVRİĞİ",
  },
  {
    state: "SİVAS",
    name: "DOĞANŞAR",
  },
  {
    state: "SİVAS",
    name: "GEMEREK",
  },
  {
    state: "SİVAS",
    name: "GÖLOVA",
  },
  {
    state: "SİVAS",
    name: "GÜRÜN",
  },
  {
    state: "SİVAS",
    name: "HAFİK",
  },
  {
    state: "SİVAS",
    name: "İMRANLI",
  },
  {
    state: "SİVAS",
    name: "KANGAL",
  },
  {
    state: "SİVAS",
    name: "KOYULHİSAR",
  },
  {
    state: "SİVAS",
    name: "MERKEZ",
  },
  {
    state: "SİVAS",
    name: "SUŞEHRİ",
  },
  {
    state: "SİVAS",
    name: "ŞARKIŞLA",
  },
  {
    state: "SİVAS",
    name: "ULAŞ",
  },
  {
    state: "SİVAS",
    name: "YILDIZELİ",
  },
  {
    state: "SİVAS",
    name: "ZARA",
  },
  {
    state: "ŞANLIURFA",
    name: "AKÇAKALE",
  },
  {
    state: "ŞANLIURFA",
    name: "BİRECİK",
  },
  {
    state: "ŞANLIURFA",
    name: "BOZOVA",
  },
  {
    state: "ŞANLIURFA",
    name: "CEYLANPINAR",
  },
  {
    state: "ŞANLIURFA",
    name: "EYYÜBİYE",
  },
  {
    state: "ŞANLIURFA",
    name: "HALFETİ",
  },
  {
    state: "ŞANLIURFA",
    name: "HALİLİYE",
  },
  {
    state: "ŞANLIURFA",
    name: "HARRAN",
  },
  {
    state: "ŞANLIURFA",
    name: "HİLVAN",
  },
  {
    state: "ŞANLIURFA",
    name: "KARAKÖPRÜ",
  },
  {
    state: "ŞANLIURFA",
    name: "SİVEREK",
  },
  {
    state: "ŞANLIURFA",
    name: "SURUÇ",
  },
  {
    state: "ŞANLIURFA",
    name: "VİRANŞEHİR",
  },
  {
    state: "ŞIRNAK",
    name: "BEYTÜŞŞEBAP",
  },
  {
    state: "ŞIRNAK",
    name: "CİZRE",
  },
  {
    state: "ŞIRNAK",
    name: "GÜÇLÜKONAK",
  },
  {
    state: "ŞIRNAK",
    name: "İDİL",
  },
  {
    state: "ŞIRNAK",
    name: "MERKEZ",
  },
  {
    state: "ŞIRNAK",
    name: "SİLOPİ",
  },
  {
    state: "ŞIRNAK",
    name: "ULUDERE",
  },
  {
    state: "TEKİRDAĞ",
    name: "ÇERKEZKÖY",
  },
  {
    state: "TEKİRDAĞ",
    name: "ÇORLU",
  },
  {
    state: "TEKİRDAĞ",
    name: "ERGENE",
  },
  {
    state: "TEKİRDAĞ",
    name: "HAYRABOLU",
  },
  {
    state: "TEKİRDAĞ",
    name: "KAPAKLI",
  },
  {
    state: "TEKİRDAĞ",
    name: "MALKARA",
  },
  {
    state: "TEKİRDAĞ",
    name: "MARMARAEREĞLİSİ",
  },
  {
    state: "TEKİRDAĞ",
    name: "MURATLI",
  },
  {
    state: "TEKİRDAĞ",
    name: "SARAY",
  },
  {
    state: "TEKİRDAĞ",
    name: "SÜLEYMANPAŞA",
  },
  {
    state: "TEKİRDAĞ",
    name: "ŞARKÖY",
  },
  {
    state: "TOKAT",
    name: "ALMUS",
  },
  {
    state: "TOKAT",
    name: "ARTOVA",
  },
  {
    state: "TOKAT",
    name: "BAŞÇİFTLİK",
  },
  {
    state: "TOKAT",
    name: "ERBAA",
  },
  {
    state: "TOKAT",
    name: "MERKEZ",
  },
  {
    state: "TOKAT",
    name: "NİKSAR",
  },
  {
    state: "TOKAT",
    name: "PAZAR",
  },
  {
    state: "TOKAT",
    name: "REŞADİYE",
  },
  {
    state: "TOKAT",
    name: "SULUSARAY",
  },
  {
    state: "TOKAT",
    name: "TURHAL",
  },
  {
    state: "TOKAT",
    name: "YEŞİLYURT",
  },
  {
    state: "TOKAT",
    name: "ZİLE",
  },
  {
    state: "TRABZON",
    name: "AKÇAABAT",
  },
  {
    state: "TRABZON",
    name: "ARAKLI",
  },
  {
    state: "TRABZON",
    name: "ARSİN",
  },
  {
    state: "TRABZON",
    name: "BEŞİKDÜZÜ",
  },
  {
    state: "TRABZON",
    name: "ÇARŞIBAŞI",
  },
  {
    state: "TRABZON",
    name: "ÇAYKARA",
  },
  {
    state: "TRABZON",
    name: "DERNEKPAZARI",
  },
  {
    state: "TRABZON",
    name: "DÜZKÖY",
  },
  {
    state: "TRABZON",
    name: "HAYRAT",
  },
  {
    state: "TRABZON",
    name: "KÖPRÜBAŞI",
  },
  {
    state: "TRABZON",
    name: "MAÇKA",
  },
  {
    state: "TRABZON",
    name: "OF",
  },
  {
    state: "TRABZON",
    name: "ORTAHİSAR",
  },
  {
    state: "TRABZON",
    name: "SÜRMENE",
  },
  {
    state: "TRABZON",
    name: "ŞALPAZARI",
  },
  {
    state: "TRABZON",
    name: "TONYA",
  },
  {
    state: "TRABZON",
    name: "VAKFIKEBİR",
  },
  {
    state: "TRABZON",
    name: "YOMRA",
  },
  {
    state: "TUNCELİ",
    name: "ÇEMİŞGEZEK",
  },
  {
    state: "TUNCELİ",
    name: "HOZAT",
  },
  {
    state: "TUNCELİ",
    name: "MAZGİRT",
  },
  {
    state: "TUNCELİ",
    name: "MERKEZ",
  },
  {
    state: "TUNCELİ",
    name: "NAZIMİYE",
  },
  {
    state: "TUNCELİ",
    name: "OVACIK",
  },
  {
    state: "TUNCELİ",
    name: "PERTEK",
  },
  {
    state: "TUNCELİ",
    name: "PÜLÜMÜR",
  },
  {
    state: "UŞAK",
    name: "BANAZ",
  },
  {
    state: "UŞAK",
    name: "EŞME",
  },
  {
    state: "UŞAK",
    name: "KARAHALLI",
  },
  {
    state: "UŞAK",
    name: "MERKEZ",
  },
  {
    state: "UŞAK",
    name: "SİVASLI",
  },
  {
    state: "UŞAK",
    name: "ULUBEY",
  },
  {
    state: "VAN",
    name: "BAHÇESARAY",
  },
  {
    state: "VAN",
    name: "BAŞKALE",
  },
  {
    state: "VAN",
    name: "ÇALDIRAN",
  },
  {
    state: "VAN",
    name: "ÇATAK",
  },
  {
    state: "VAN",
    name: "EDREMİT",
  },
  {
    state: "VAN",
    name: "ERCİŞ",
  },
  {
    state: "VAN",
    name: "GEVAŞ",
  },
  {
    state: "VAN",
    name: "GÜRPINAR",
  },
  {
    state: "VAN",
    name: "İPEKYOLU",
  },
  {
    state: "VAN",
    name: "MURADİYE",
  },
  {
    state: "VAN",
    name: "ÖZALP",
  },
  {
    state: "VAN",
    name: "SARAY",
  },
  {
    state: "VAN",
    name: "TUŞBA",
  },
  {
    state: "YALOVA",
    name: "ALTINOVA",
  },
  {
    state: "YALOVA",
    name: "ARMUTLU",
  },
  {
    state: "YALOVA",
    name: "ÇINARCIK",
  },
  {
    state: "YALOVA",
    name: "ÇİFTLİKKÖY",
  },
  {
    state: "YALOVA",
    name: "MERKEZ",
  },
  {
    state: "YALOVA",
    name: "TERMAL",
  },
  {
    state: "YOZGAT",
    name: "AKDAĞMADENİ",
  },
  {
    state: "YOZGAT",
    name: "AYDINCIK",
  },
  {
    state: "YOZGAT",
    name: "BOĞAZLIYAN",
  },
  {
    state: "YOZGAT",
    name: "ÇANDIR",
  },
  {
    state: "YOZGAT",
    name: "ÇAYIRALAN",
  },
  {
    state: "YOZGAT",
    name: "ÇEKEREK",
  },
  {
    state: "YOZGAT",
    name: "KADIŞEHRİ",
  },
  {
    state: "YOZGAT",
    name: "MERKEZ",
  },
  {
    state: "YOZGAT",
    name: "SARAYKENT",
  },
  {
    state: "YOZGAT",
    name: "SARIKAYA",
  },
  {
    state: "YOZGAT",
    name: "SORGUN",
  },
  {
    state: "YOZGAT",
    name: "ŞEFAATLİ",
  },
  {
    state: "YOZGAT",
    name: "YENİFAKILI",
  },
  {
    state: "YOZGAT",
    name: "YERKÖY",
  },
  {
    state: "ZONGULDAK",
    name: "ALAPLI",
  },
  {
    state: "ZONGULDAK",
    name: "ÇAYCUMA",
  },
  {
    state: "ZONGULDAK",
    name: "DEVREK",
  },
  {
    state: "ZONGULDAK",
    name: "EREĞLİ",
  },
  {
    state: "ZONGULDAK",
    name: "GÖKÇEBEY",
  },
  {
    state: "ZONGULDAK",
    name: "KİLİMLİ",
  },
  {
    state: "ZONGULDAK",
    name: "KOZLU",
  },
  {
    state: "ZONGULDAK",
    name: "MERKEZ",
  },
];

export const passwordModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "calc(100vw - 80px)",
    width: "460px",
    padding: "1.5rem",
    maxHeight: "calc(100vh - 100px)",
    transform: "translate(-50%, -50%)",
    background: "var(--bs-white)",
    borderRadius: "1rem",
  },
  overlay: {
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(10px)",
  },
};

export const transTypes = {
  0: "Devir",
  1: "Para Yatırma",
  2: "Kredi Kartı İle Para Yatırma",
  3: "Para Çekim",
  4: "TJK Oynama",
  5: "TJK Kazanç",
  9: "Ödül Talebi",
  10: "Düzeltme",
  12: "Süper Tombala Oynama",
  13: "Süper Tombala Kazanç",
  14: "Referans Kodu Hediyesi",
  15: "Zeplin Oynama",
  16: "Zeplin Kazanç",
  17: "Kampanya Kazanç",
  18: "Meyve Kulesi Oynama",
  19: "Meyve Kulesi Kazanç",
  20: "Çarkıfelek Oynama",
  21: "Çarkıfelek Kazanç",
  22: "Kupon Kampanyası Yükleme",
  23: "Ateş Topları Oynama",
  24: "Ateş Topları Kazanç",
  25: "Kredi Kartı İadesi",
  26: "Taş Kağıt Makas Oynama",
  27: "Taş Kağıt Makas Kazanç",
  28: "Galaksi Turu Oynama",
  29: "Galaksi Turu Kazanç",
  30: "Işıltılı Taşlar Oynama",
  31: "Işıltılı Taşlar Kazanç",
  32: "Süper Plinko Oynama",
  33: "Süper Plinko Kazanç",

  35: "Eşya Piyangosu Oynama",
  36: "Eşya Piyangosu Hediye",
  37: "Kazı Kazan Oynama",
  38: "Kazı Kazan Kazanç",
  44: "Kunduzun Macerası Oynama",
  45: "Kunduzun Macerası Kazanç",
  46: "TJK Kupon Kazancı",
  47: "Puan",
};

export const listGamePages = [
  paths.scratch.url,
  paths.scratchMyCards.url,
  paths.scartchRules.url,
  paths.superBingo.url,
  paths.zeplin.url,
  paths.fireballs.url,
  paths.fruitTowers.url,
  paths.superWheel.url,
  paths.rockPaperScissors.url,
  paths.turboMines.url,
  paths.crashX.url,
  paths.turboPlinko.url,
  paths.hamsta.url,
];
