import React, {useEffect, useState} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {useParams} from "react-router-dom";
import {tjkBetSlipOptions} from "../../services/betslip.service";
import paths from "../../paths";

const tabs = [
  { value: 0, label: "TJK TV" },
  { value: 1, label: "TJK TV (Yurt Dışı)" },
  // { value: 2, label: "TAY TV" },
];


let videoWrapper:any = null;
let width:any = 0;

export function Stream() {

  const params = useParams();

  const [selectedTab, setSelectedTab] = useState<any>(params?.tab ? tabs[Number(params?.tab)]: tabs[0]);
  const forceUpdate = useForceUpdate();

  const handleSelect = (e: any) => {
    if (e.target.id) {
      setSelectedTab(tabs[e.target.id]);
    }
  };

  useEffect(() => {

    videoWrapper = document.getElementById("id_video_wrapper");
    if(videoWrapper) {
      width = videoWrapper.offsetWidth;
      forceUpdate();
    }

    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  const resizeWindow = () => {
    if(width !== window.innerWidth){
      if(videoWrapper){
        width = videoWrapper.offsetWidth;
      }
      forceUpdate();
    }
  }



  let videoWrapper = document.getElementById("id_video_wrapper");
  if(videoWrapper){
    console.log(videoWrapper.offsetWidth);
  }



  return (<>
  <div className="page-widget">

    <svg style={{display: "none"}}>
      <defs>
        <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
      </defs>
    </svg>


    <ul className="nav nav-tabs">
      {tabs.map((tab:any, index:number) => {
        return (
          <li
            key={`home-tabs-${index}`}
            className={`nav-item ${selectedTab.value === tab.value ? "active" : ""}`}
            onClick={handleSelect}
          >
            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
              <use xlinkHref="#tabshape"></use>
            </svg>
            <a className="nav-link" id={`${index}`}>
              {tab.label.toUpperCase()}
            </a>
            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
              <use xlinkHref="#tabshape"></use>
            </svg>
          </li>
        );
      })}
    </ul>

    <div className={`${window.location.pathname.includes(`${paths.betting.url}`) ? 'p-1' : 'p-3'}`}>
      <div className="row">
        <div className="col-12">

          <div id="id_video_wrapper">


            {selectedTab.value === 0 &&
                <iframe width={width} height={width*315/560} src="https://www.youtube.com/embed/g89RQMJtK6E?si=J7XW7PSxljzq0C64?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>}

            {selectedTab.value === 1 && <iframe width={width} height={width * 315 / 560}
                                                src="https://www.youtube.com/embed/v05yoe3ZafE?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>}

            {selectedTab.value === 2 &&<iframe width={width} height={width*315/560} src="https://www.youtube.com/embed/v05yoe3ZafE?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"
                                               title="YouTube video player" frameBorder="0"
                                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                               allowFullScreen></iframe>}


          </div>

        </div>
      </div>
    </div>

  </div>
  </>)
}
