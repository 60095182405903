import {bulletinUrlPrefix, warningLogStyle} from "../constants/global.vars";
import {ApiService} from "./http.service";
import {ServerDate} from "./misc.functions";
import moment from "moment";
import {
    availableBettingSubTypes,
    availableBettingTypes,
    availableHippodromes, availableHippodromesSubject,
    bettingTypes, BulletinMap, BulletinMapCardID,
    DailyRaces, jewelryMapping,
    racesActionSubject
} from "../store/bulletin.store";
import {Hippodrome, Run} from "../models/bulletin.models";
import _ from "lodash";
import {
    addAllToBetSlip,
    addToTJKBetSlip,
    betSlipActionSubject, removeHorseFromBetSlip,
    restoreBetSlip,
    tjkBetSlipOptions
} from "./betslip.service";


let bullettinTimer: any = null;
let bullettinNextTimer: any = null;
let api: any = null;


let THIS: any = null;

export class BulletinProvider {

    init() {
        THIS = this;
        this.stop();
        this.call();
        setTimeout(() => {
            THIS.call(1)
        }, 1000);
        this.start();
    }

    start() {
        bullettinTimer = setInterval(this.call, 5 * 1000);
        bullettinNextTimer = setInterval(this.nextDay, 30 * 1000);
    }

    nextDay() {
        THIS.call(1);
    }

    call(plus: number = 0) {

        let today = ServerDate();
        let date = moment(new Date(today.getTime() + (plus * 24 * 60 * 60 * 1000))).format('DDMMYYYY');

        api = new ApiService();
        let url = `${bulletinUrlPrefix}/${date}`;
        // if (plus > 0) {
        //   url += date
        // }else if (plus === -1){
        //   date = moment(new Date(today.getTime())).format('DDMMYYYY');
        //   url += date;
        // }

        api.start("GET", `${url}`, null, false).then((response: any) => {
            // if (response.data.length === 0){
            //   THIS.call(-1)
            // }

            let isUpdated: any = [];
            for (let hippodrome of response.data) {

                hippodrome.hippodrome = hippodrome.hippodrome.replace(' Hipodromu', '');
                hippodrome.opening_date = new Date(hippodrome.date.split('T')[0] + 'T' + hippodrome.opening + ':00');
                hippodrome.runs.filter((run: any) => {
                    run.running_date = new Date(hippodrome.date.split('T')[0] + 'T' + run.time + ':00');
                    run.isFirstSix = false;
                    var isFirstSix = run.bets.find((el: any) => el.code === '35' || el.code === "1");
                    if (isFirstSix) {
                        run.isFirstSix = true;
                    }
                    run.isSecondSix = false;
                    var isSecondSix = run.bets.find((el: any) => el.code === '36');
                    if (isSecondSix) {
                        run.isSecondSix = true;
                    }
                    return run;
                });

                // fixme : bu konuyu sonra tekrar düşün
                // hippodrome.status = hippodrome.runs.filter((el:any)=>Number(el.status)===0).length > 0;


                let exHippodrome: any = DailyRaces.find((el: any) => el._id === hippodrome._id);

                if (exHippodrome) {
                    for (const [key, value] of Object.entries(hippodrome)) {
                        if (!['runs', 'wager_details', 'nextRun'].includes(key) && exHippodrome[key] !== value) {
                            exHippodrome[key] = value;
                            isUpdated.push(hippodrome._id);
                        } else if (key === 'wager_details') {
                            if (hippodrome.wager_details) {
                                if (!exHippodrome.wager_details) {
                                    exHippodrome['wager_details'] = [];
                                }

                                for (let wager of hippodrome.wager_details) {
                                    let exBet = exHippodrome.wager_details.find((el: any) => Number(el.type) === Number(wager.type));
                                    if (exBet) {
                                        for (const [bKey, bValue] of Object.entries(wager)) {
                                            if (['price', 'runs'].includes(bKey)) {
                                                exBet[bKey] = bValue;
                                                isUpdated.push(hippodrome._id);
                                            }
                                        }
                                    } else {
                                        exHippodrome.wager_details.push(wager);
                                        isUpdated.push(hippodrome._id);
                                    }
                                }


                                exHippodrome.wager_details.forEach((wager: any, ix: number) => {
                                    let exBet = hippodrome.wager_details.find((el: any) => Number(el.type) === Number(wager.type));
                                    if (!exBet) {
                                        exHippodrome.wager_details.splice(ix, 1)
                                    }
                                })


                            } else {
                                exHippodrome['wager_details'] = [];
                            }
                        } else if (key === 'runs') {
                            if (hippodrome.runs) {
                                for (let newRun of hippodrome.runs) {
                                    let exRun: any = exHippodrome.runs.find((el: any) => el.no === newRun.no);
                                    if (exRun) {
                                        for (const [rkey, rValue] of Object.entries(newRun)) {
                                            if (!['horses'].includes(rkey) && exRun[rkey] !== rValue) {
                                                exRun[rkey] = rValue;
                                                isUpdated.push(hippodrome._id);
                                            } else if ('horses' === rkey) {
                                                if (newRun.horses) {
                                                    for (let newHorse of newRun.horses) {
                                                        let exHorse = exRun.horses.find((el: any) => el.no === newHorse.no);
                                                        if (exHorse) {
                                                            for (const [hkey, hValue] of Object.entries(newHorse)) {
                                                                if (exHorse[hkey] !== hValue) {
                                                                    exHorse[hkey] = hValue;
                                                                    // koşmaza dönen at betlipten çıkarılıyor
                                                                    if (hkey === 'run_status' && hValue === false) {
                                                                        removeHorseFromBetSlip(exHippodrome._id, exRun.no, exHorse.no)
                                                                    }
                                                                    isUpdated.push(hippodrome._id);
                                                                }
                                                            }
                                                        } else {
                                                            // at ekle
                                                            if (newHorse.run_status) {
                                                                exRun.horses.push(newHorse);
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    exRun['horses'] = [];
                                                }
                                            }
                                        }
                                    } else {
                                        // koşu eklenecek
                                        if (Number(newRun.status) === 0) {
                                            exHippodrome.runs.push(newRun)
                                        }

                                        BulletinMap[exHippodrome._id].runsMap[Number(newRun.no)] = newRun;

                                    }
                                }
                            } else {
                                exHippodrome['runs'] = [];
                            }
                        }
                    }
                    THIS.setNextRun(exHippodrome);
                } else {
                    console.log(`%c* Yeni Hipodrom Eklendi ${hippodrome.hippodrome}`, warningLogStyle)
                    THIS.setNextRun(hippodrome);

                    BulletinMap[hippodrome._id] = hippodrome;
                    BulletinMapCardID[hippodrome.card_id] = BulletinMap[hippodrome._id];
                    BulletinMap[hippodrome._id].runsMap = {}
                    hippodrome.runs.forEach((r: any) => {
                        BulletinMap[hippodrome._id].runsMap[Number(r.no)] = r;
                        BulletinMap[hippodrome._id].runsMap[Number(r.no)].horseMap = {}
                        r.horses.forEach((h: any) => {
                            BulletinMap[hippodrome._id].runsMap[Number(r.no)].horseMap[String(h.no)] = h;
                        })
                    })

                    DailyRaces.push(hippodrome);
                    isUpdated.push(hippodrome._id);
                }
            }

            restoreBetSlip();

            if (isUpdated.length > 0) {
                bulletinProvider.getAvailableHippodromes();
                racesActionSubject.next({'action': 'update', 'id': isUpdated})
            }

            try {
                // fixme : prod a geçince silinmeli
                // @ts-ignore
                window.globalRaces = DailyRaces;
            } catch (e) {
            }

        }).catch((error: any) => {
            console.log(error)
        }).finally(() => {
        });

    }


    setNextRun(hippodrom: Hippodrome) {
        try {
            hippodrom.nextRun = hippodrom.runs.find((r: any) => Number(r.no) === Number(hippodrom.wager_details.find((e: any) => e.type === '1')?.runs[0]));
        } catch (e) {
            hippodrom.nextRun = null;
        }
    }


    getAvailableHippodromes() {
        let ordered: any = [];
        try {
            ordered = _.orderBy(DailyRaces, [(e: any) => Number(moment(new Date(e.date)).format("YYYYMMDD")), (e: any) => Number(e.foreign) * -1, (e: any) => new Date(e.date.split('T')[0] + 'T' + e.opening),], ['asc']);
            Object.assign(DailyRaces, ordered);
        } catch (e) {
            ordered = _.orderBy(DailyRaces, [(e: any) => Number(moment(new Date(e.date)).format("YYYYMMDD")), (e: any) => Number(e.foreign) * -1], ['asc']);
            Object.assign(DailyRaces, ordered);
        }

        ordered.forEach((e: any) => {
            let isEx = availableHippodromes.find((x: any) => x._id === e._id)
            if (!isEx) {
                if (e.status !== false && e.wager_details.length > 0) {
                    availableHippodromes.push(e);
                }
            } else {
                if (isEx.status === false && isEx.wager_details.length === 0) {
                    let indx = availableHippodromes.findIndex((el: any) => el._id === isEx._id);
                    availableHippodromes.splice(indx, 1);
                    availableHippodromesSubject.next('remove');
                }
            }
        })
        return availableHippodromes;
    }

    getAvailableBettingTypes(hippodrome: Hippodrome) {
        let hippodromeBetTypes: any = [];
        hippodromeBetTypes = hippodrome.wager_details.map((e: any) => Number(e.type));
        // console.log("hippodromeBetTypes", hippodromeBetTypes)
        availableBettingTypes.splice(0, availableBettingTypes.length);
        availableBettingTypes.push(...bettingTypes.filter((el: any) => {
            if (hippodromeBetTypes.includes(el.id) && el.available === true) {
                let item: any = hippodrome.wager_details.find((x: any) => Number(x.type) === Number(el.id));
                el.price = item.price;
                el.runs = item.runs;
                return el;
            }
        }))


        // if(hippodrome.wager_details.length === 0){
        //   bettingTypes.forEach((el:any)=>{
        //
        //     // fixme gereksiz bir yer başka çözüm bulunmalı rezerve kuponlar için
        //     if([15,16,17,18,19].includes(el.id)){
        //       el.runs = [];
        //       if(hippodrome.runs.length > 8 && (hippodrome.runs.length + 1 - (el.column * 2)) > 1) {
        //         el.runs.push(hippodrome.runs.length + 1 - (el.column * 2));
        //       }
        //       el.runs.push(hippodrome.runs.length+1 - el.column);
        //
        //     }else{
        //       el.runs = hippodrome.runs.map((r:Run)=>Number(r.no));
        //     }
        //   })
        //   availableBettingTypes.push(...bettingTypes);
        // }

        return availableBettingTypes;
    }

    generateSubTypeLabel(bettingType: any, r: any) {
        const chunkSize = bettingType.subType;
        let chunk: any = [];
        for (let i = r; i < r + chunkSize; i += 1) {
            chunk.push(i)
        }
        return chunk.join('-');
    }


    getAvailableBettingSubTypes(bettingType: any) {
        availableBettingSubTypes.splice(0, availableBettingSubTypes.length);
        availableBettingSubTypes.push(...bettingType.runs.map((r: any) => {
            return {id: r, label: this.generateSubTypeLabel(bettingType, r)}
        }));
    }

    selectBettingType(hippodrome: Hippodrome, betType: any) {
        availableBettingSubTypes.splice(0, availableBettingSubTypes.length);
        const bettingType: any = availableBettingTypes.find((ex: any) => ex.id === Number(betType))
        tjkBetSlipOptions.bettingType = bettingType;
        tjkBetSlipOptions.complete = false;
        if (bettingType) {
            this.getAvailableBettingSubTypes(bettingType);
            this.selectBettingSubType(availableBettingSubTypes[0]);
        }
    }


    selectBettingSubType(betSubType: any) {
        tjkBetSlipOptions.bettingSubType = betSubType;
        betSlipActionSubject.next(true);
    }

    selectBettingColumn(column: any) {
        tjkBetSlipOptions.column = Number(column);
    }

    stop() {
        clearInterval(bullettinTimer);
    }

}

export const getPrettyFormatLastSix = (last6: any) => {
    let htmlResponse = '';
    if (last6 && (last6.indexOf('K') !== -1 || last6.indexOf('C') !== -1)) {
        const lastRuns = last6.match(/.{1,2}/g);
        for (let lR of lastRuns) {
            htmlResponse += `<span class="last-run-${lR[0]}">${lR[1]}</span>`
        }
    } else {
        htmlResponse = last6
    }
    return htmlResponse;
}

export const getPrettyFormatJewelry = (jewelry: any) => {
    let htmlResponse = '';
    if (jewelry) {
        const jewelries = jewelry.split(' ');
        for (let jewel of jewelries) {
            let textJewel = jewelryMapping[jewel]
            htmlResponse += `<span class="d-block mb-2"><b>${jewel}:</b> ${textJewel || jewel}</span>`
        }
    }
    return htmlResponse;
}

export const bulletinProvider = new BulletinProvider();
