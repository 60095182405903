import React, { useEffect, useState } from "react";
import { exceptionModal } from "../../services/misc.functions";
import { ApiService } from "../../services/http.service";
import { env } from "../../constants/global.vars";
import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import {isMobile} from "../../App";

export function BankAccounts() {
  const [accountList, setAccountList] = useState<any>(null);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  useEffect(() => {
    getListAccount();
  }, []);

  const getListAccount = () => {
    const api = new ApiService();
    api
      .start("get", env.accounting_api + "/member/api/list-account/", null, true)
      .then((res: any) => {
        // console.log(res)
        if (res && res.status) {
          setAccountList(res.data);
          setSelectedAccount(res.data[0]?.id);
        } else {
          setAccountList([]);
        }
      })
      .catch((e: any) => {
        exceptionModal(e);
        setAccountList([]);
      });
  };

  const deleteAccount = (accountID: any) => {
    const api = new ApiService();
    api
      .start(
        "post",
        env.accounting_api + "/member/api/create-member-account/",
        {
          id: accountID,
          delete: true,
        },
        true
      )
      .then((res: any) => {
        // console.log(res)
        if (res && res.status) {
          toast.success("Hesap başarıyla silindi.");
          accountList.splice(
            accountList.findIndex((account: any) => account.id === accountID),
            1
          );
          setAccountList([...accountList]);
        }
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    const api = new ApiService();
    payload.iban = payload.iban.replaceAll(" ", "");
    api.start("post", env.accounting_api + "/member/api/create-member-account/", payload, true).then((res: any) => {
      if (res && res.status) {
        toast.success("Hesap başarıyla eklendi.");
        window.location.reload();
        getListAccount();
        reset({ iban: "", name: "" });
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <h1>Banka Hesapları</h1>
      <div className="page-widget">
        <div className="p-3">
      <div className="row g-3 mb-3">
        {/*<div className="d-flex form-row w-100 align-items-center gap-2">*/}

        <div className={`col-lg-3 mb-0 d-flex align-items-center ${isMobile ? "justify-content-start" : "justify-content-end"}`}>
          <p className={`mb-0`}  >BANKA HESAPLARIM</p>
        </div>
        <div className={"col-lg-6 col-sm-6"}>
          <select className="w-100" onChange={(e) => setSelectedAccount(e.target.value)} value={selectedAccount}>
            {accountList?.map((account: any, index: number) => {
              return (
                  <option key={index} value={account.id}>
                    {account.bank_name} - {account.iban}
                  </option>
              );
            })}
          </select>
        </div>
        <div className={"col-lg-3 col-sm-12"}>
          <button
              className="btn btn-warning px-5 w-100"
              onClick={() => deleteAccount(selectedAccount)}
              disabled={accountList?.length === 0 || !selectedAccount}
          >
            SİL
          </button>
        </div>


        {/*</div>*/}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div
              className={`col-lg-3 mb-0 d-flex align-items-center ${isMobile ? "justify-content-start" : "justify-content-end"}`}>
            <p className="mb-0">IBAN</p>
          </div>

          <div className={"col-lg-6 col-sm-6"}>
            <InputMask
                mask="TR99 9999 9999 9999 9999 9999 99"
                placeholder={"TRXX XXXX XXXX XXXX XXXX XXXX XX"}
                {...register<any>("iban", {required: true})}
            />
            {errors.iban && <p className="invalid-feedback">IBAN numaranızı giriniz.</p>}
          </div>
        </div>

        <div className="row mb-3">
          <div
              className={`col-lg-3 mb-0 d-flex align-items-center ${isMobile ? "justify-content-start" : "justify-content-end"}`}>
            <p className="mb-0">TANIM</p>
          </div>

          <div className={"col-lg-6 col-sm-6"}>
          <input type="text" {...register("name", {required: true})} />
            {errors.name && <p className="invalid-feedback">Tanım giriniz.</p>}
          </div>
        </div>

        <div>
        <p>ÖNEMLİDİR:</p>

          <p>
            Vadesiz mevduat hesabınızı tanımlayan 26 haneli IBAN numaranızı "IBAN" alanına eklemelisiniz. Dilerseniz bu
            hesaba bir isim belirleyerek kolay hatırlamanıza yardımcı olabilirsiniz.
          </p>
          <ul>
            <li>
              Sadece kendinize ait bir banka hesabından para çekebileceğiniz için eklediğiniz banka hesap numaranız size
              ait olmalıdır.
            </li>
            <li>En fazla bir banka hesabını varsayılan hesap olarak belirleyebilirsiniz.</li>
            <li>Birden fazla hesap numarası da tanımlayabilirsiniz.</li>
            <li>Varsayılan hesabı istediğiniz zaman değiştirebilirsiniz.</li>
            <li>İlk tanımladığınız hesap sistem tarafından otomatik olarak "Varsayılan Hesabınız" belirlenecektir.</li>
            <li>
              Birden fazla tanımlı banka hesap bilginiz olması durumunda bir tanesinin mutlaka varsayılan hesap olması
              gerekmektedir.
            </li>
            <li>
              Varsayılan hesabınızı sildiğiniz koşulda EN SON EKLENEN hesap bilginiz "Varsayılan Hesap olarak otomatik
              atanacaktır.
            </li>
            <li>
              Mobil uygulama üzerinden de IBAN numaranızı (banka hesap bilgilerinizi) tanımlayabilir ya da varsayılan
              hesabınızda değişiklik yapabilirsiniz.
            </li>
          </ul>
        </div>

        <button className="btn btn-warning w-100" type="submit">
          Kaydet
        </button>
      </form>
    </div>
      </div>
    </>
  );
}
